import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import * as pbi from "powerbi-client";
import jwt_decode from "jwt-decode";
import { useMsal } from "@azure/msal-react";

import { makeStyles, Container, Typography } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import CircularProgress from "@material-ui/core/CircularProgress";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import AutorenewIcon from "@material-ui/icons/Autorenew";

import Page from "../utils/Page";
import { getAutorenewedToken, getUserName } from "../auth/msalUtils";
import { adalConfig } from "../auth/adalConfig";
import MaterialButton from "../utils/Button";
import Emitter from "../utils/Emitter";
import DashboardComment from "./DashboardComment";
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import MuiButton from '../utils/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
  },
  fabProgress: {
    color: theme.palette.primary.main,
    marginTop: "20%",
    marginLeft: "35%",
    marginBottom: "15%",
  },
  newButtonFirst: {
    marginLeft: "0px",
    float: "left",
    marginRight: "1%",
    fontSize: "14px",
    background: theme.palette.whiteColor.main,
  },
  newButton: {
    float: "left",
    marginRight: "1%",
    fontSize: "14px",
    background: theme.palette.whiteColor.main,
  },
  reportClasses: {
    marginBottom: "1%",
    maxHeight: "790px",
    maxWidth: "1400px",
    height: "calc(75vw * 9/16)",
    width: "75vw",
  },
}));

const DisplayPowerBIPage = (props) => {
  const classes = useStyles();
  const routePath = useLocation();
  const [titleStr, setTitleStr] = useState("");
  const { instance, accounts } = useMsal();
  const [isLoaded, setIsLoaded] = useState(false);
  const [pbiReport, setPbiReport] = useState(null);
  const [dashBoardId, setDashBoardId] = useState(null);
  const [dashBoardName, setDashBoardName] = useState(null);
  const [visualName, setVisualName] = useState(null); 
  const [currentUser, setCurrentUser] = useState(null);
  const [userRoles, setUserRoles] = useState({
    reader:false,
    writer:false,
    approver:false
  })
  //const [disableCommentry, setDisableCommentry] = useState(null);
  const disableCommentry = process.env.REACT_APP_DISABLE_COMMENTRY
  const { open, commentId } = routePath.state || {};
  const navigate = useNavigate();
  //SNACKBAR NOTIFICATIONS
  const [powerBIConfig, setPowerBIConfig] = useState({
    type: "report", // Supported types: report, dashboard, tile, visual and qna
    id: undefined,
    embedUrl: undefined,
  });

  const  getCurrentUserDetail = async () => {
    return fetch(
      process.env.REACT_APP_API_PATH + "/user/current",
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      setCurrentUser(data);
    });
  }
  const  getUserRole = async () => {
    return fetch(
      process.env.REACT_APP_API_PATH + "/usersecurity/getUserRoles?dashboardId="+dashBoardId,
      {
        method: "GET",
        headers: { authorization: await getAutorenewedToken() },
      }
    ).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      const userRole = {
        approver: data?.some(user => user.approver),
        writer: data?.some(user => user.writer),
        reader: data?.some(user => user.reader),
      };
      setUserRoles(userRole)
      setCurrentUser(data);
    });
  }

  const getPowerBIToken = async (scope) => {
    if (accounts && accounts.length > 0) {
      const accessTokenRequest = {
        scopes: [scope],
        account: accounts[0],
      };
      try {
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        return response.accessToken;
      } catch (error) {
        if (error) {
          await new Promise(() =>
            instance.acquireTokenRedirect(accessTokenRequest)
          );
        }
        throw new Error("Cannot get access token.");
      }
    }
  };

  const refreshPowerBiToken = async () => {
    let token;

    try {
      token = await getPowerBIToken(adalConfig.powerBiScope);
    } catch (e) {
      return;
    }

    const millisToExpiry = jwt_decode(token).exp * 1000 - Date.now();
    const twoMinutesMillis = 1000 * 60 * 2;
    // Queue another refresh, two minutes before the token is due to expire
    setTimeout(() => refreshPowerBiToken(), millisToExpiry - twoMinutesMillis);

    return token;
  };

  const getCurrentPowerBIUrl = async (inPath) => {
    const powerBIPath = props.value === undefined ? inPath.pathname : props.value;
    if (powerBIPath.length !== 0) {
      setIsLoaded(false)
      const authToken = await getAutorenewedToken();
      fetch(
        process.env.REACT_APP_API_PATH +
          "/dashboards/route?route=" +
          encodeURIComponent(powerBIPath),
        {
          method: "GET",
          headers: { authorization: authToken },
        }
      )
        .then(async (response) => {
          const data = await response.json();
          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          setDashBoardId(data.dashboardId)
          setDashBoardName(data.dashboardName)
          const powerBIWebUrlArray = data.dashboardPowerBiUrl
            ? data.dashboardPowerBiUrl.split("/")
            : [];

            fetch(
              process.env.REACT_APP_API_PATH +
                "/embeddedtoken?WorkspaceId=" +
                powerBIWebUrlArray[4] + "&ReportId=" +powerBIWebUrlArray[6],
              {
                method: "GET",
                headers: { authorization: authToken },
              }
            ).then(async (response) => {
              const embedData = await response.json();

          setPowerBIConfig({
            type: "report",
            id: powerBIWebUrlArray[6],
            groupId: powerBIWebUrlArray[4],
            pageName: powerBIWebUrlArray[7],
            embedUrl: embedData.embedUrl,
            accessToken: embedData.accessToken,
            tokenType: pbi.models.TokenType.Embed,
            permission: pbi.models.Permissions.Read,
            viewMode: pbi.models.ViewMode.View,
            settings: {
              localeSettings: {
                language: "en",
                formatLocale: "en-au",
              },
              filterPaneEnabled: false,
              navContentPaneEnabled: data?.showNavigationBar,
              layoutType: pbi.models.LayoutType.Custom,
              customLayout: {
                displayOption: pbi.models.DisplayOption.FitToPage,
              },
            },
          });

          setTitleStr(data.dashboardName);
          setIsLoaded(true);
        });
        });
    }
  };

  //THIS WILL FETCH THE DATA FROM DB ON COMPONENT MOUNTING
  useEffect(() => {
    getCurrentPowerBIUrl(routePath);
    getCurrentUserDetail();
  }, [routePath]); 

  useEffect(() => {
    if(dashBoardName)
    {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN ? process.env.REACT_APP_MIXPANEL_TOKEN : "")
      mixpanel.track_pageview({
        user_id:window.sessionStorage.getItem('USER_ID'),
        dashboardName:dashBoardName
      });

    }
  }, [dashBoardName]); 
  
  useEffect(() => {
    if(dashBoardId)
    {
      getUserRole()
    }
  }, [dashBoardId]); 

  const printPDF = () => {
    mixpanel.track("Dashboard Print",{
      user_id:window.sessionStorage.getItem('USER_ID'),
      dashboardName:dashBoardName
    })
    pbiReport.print();
  };

  const expand2Fullscreen = () => {
    mixpanel.track("Dashboard Fullscreen",{
      user_id:window.sessionStorage.getItem('USER_ID'),
      dashboardName:dashBoardName
    })
    pbiReport.fullscreen();
  };

  const reloadPBI = () => {
    window.location.reload(false);
  };
  const eventHandlersMap = new Map([
    [
      "visualClicked",
      function (event) {
        setVisualName('');
        setVisualName(event.detail.visual.name);
      }
    ],
  ]);

  return (
    <Page title={titleStr} className={classes.root}>
      <Container maxWidth={false}>
        {isLoaded ? (
          <>
            <PowerBIEmbed
              embedConfig={powerBIConfig}
              eventHandlers = { eventHandlersMap }
              cssClassName={classes.reportClasses}
              getEmbeddedComponent={(embedObject) => {
                setPbiReport(embedObject);
              }}
            />
            <MaterialButton
              text="Print"
              variant="outlined"
              color="primary"
              startIcon={<PrintIcon />}
              className={classes.newButtonFirst}
              onClick={() => {
                printPDF();
              }}
            />

            <MaterialButton
              text="Fullscreen"
              variant="outlined"
              color="primary"
              startIcon={<FullscreenIcon />}
              className={classes.newButton}
              onClick={() => {
                expand2Fullscreen();
              }}
            />
            {/* <MuiButton variant="outlined" color="primary" text="Back" type="submit" startIcon={<FullscreenIcon />} style={{backgroundColor:"white"}} /> */}

            <MaterialButton
              text="Reload"
                variant="outlined"
              color="primary"
              startIcon={<AutorenewIcon />}
              className={classes.newButton}
              onClick={() => {
                reloadPBI();
              }}
            />

            {
              dashBoardId && dashBoardName && disableCommentry == "false" ?
                <DashboardComment
                  dashboardId={dashBoardId}
                  dashboardName={dashBoardName}
                  addComment={currentUser.addComment}
                  openDrawer={open}
                  commentId={commentId}
                  userRole={userRoles}
                >

                </DashboardComment>
                : null
            }
          </>
        ) : (
          <CircularProgress size={84} className={classes.fabProgress} />
        )}
      </Container>
    </Page>
  );
};

export default DisplayPowerBIPage;
